html{
  height: 100%;
}
body {
  min-height: 100%;
}

/* Icons */
i.o-6 {
  opacity: 0.6;
}

/* Inset brand in nav bar */
.navbar .brand a {
  color: #0097E2;
}

.brand.brand-inverse {
  color: rgba(0,0,0,0.4);
  font-weight: bold;
  text-shadow: 0 1px 0 rgba(255, 255, 255, .1), 0 0 30px rgba(255, 255, 255, .125);
}

@media (max-width: 991px) {
    .navbar {
        overflow: auto;
        max-height: 85vh;
        align-items: flex-start;
    }
}
/* Centered title */
.navbar .center-title {
  position: absolute;
  left: 50%;
  width: 140px;
  margin-left: -70px;
  text-align: center;
  padding-left: 0px;
  padding-right: 0px;
}

/* Clickable Experiments & Datasets */
.clickable-tile {
	cursor: pointer;
}

.abstract {
  text-overflow: ellipsis;
}

.abstract-clickable {
  overflow: hidden;
  text-overflow: ellipsis;
}

#collapseDescription {
    font-size: 14px;
    line-height: 1.5;
}
#collapseDescription p.collapsing {
    min-height: 42px !important;
}
#collapseDescription p.collapse:not(.show) {
    height: 42px !important;
    overflow: hidden;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
#collapseDescription p.collapsed:after  {
    content: '+ Show Description';
}

#collapseDescription p:not(.collapsed):after {
    content: '- Hide Description';
}

.truncated-text {
	white-space: nowrap;
}

/* Download checkboxes for datasets */
.dataset-download-box {
	display: none;
}

form .dataset-download-box {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

div.copylink {
    font-weight:bold;
    padding: 7px;
    margin: 5px;
    border: 1px solid #CCCCCC;
    border-radius: 3px 3px 3px 3px;
    word-wrap: break-word;
}

.download-selected {
    font-weight: bold;
    margin-bottom: 12px;
}

/*override for bootstrap nav pills*/
.nav-pills > .active > a, .nav-pills > .active > a:hover {
    background-color: #D3DFE6;
    font-weight: bold;
    color: #0088CC;
}

/* Dataset links */

a.dataset-link {
    font-size: 110%;
    color: black;
}

a.dataset-link :hover {
    color: black;
}

/* Black links */

a.explink, a.dllink, a[rel=license], table td a {
    color: black;
}

a.explink, a.dllink, a[rel=license] :hover, table td a {
    color: black;
}

/* Group links */

a.group-link {
    color: black;
    font-weight: normal;
}

a.group-link :hover {
    color: black;
    font-weight: normal;
}

i.group-icon :hover {
    cursor: pointer;
}

/* Miscellaneous */

.datafile_name {
    word-break: break-all;
}

.archived-file {
    color: #777777;
}

.info-box {
    border: 1px solid #DDDDDD;
    padding: 5px 10px 5px 10px;
    margin: 5px 20px 25px 10px;
}

.file-select {
    background-color: #D3DFE6;
}

.table tbody tr.file-select:hover td, .table tbody tr.file-select:hover th {
    background-color: #D3DFE6;
}

.pagination {
    height: auto;
}

.parameter_name {
    width: 203px;
}

.parameter_value {
    width: 400px;
}

.brand a:hover {
    color: white;
    text-decoration: none;
}

.experiments .card {
    border-color: rgba(0, 0, 0, 0.06);
}

.experiments .card .accordion-toggle {
    cursor: pointer;
}

.experiments .card-body {
    background-color: #E8F6FF;
}

.experiments .accordion-inner .dataset-list-header {
    font-style: italic;
    padding-top: 5px;
    padding-left: 5px;
}

/* index page */
.experiments .accordion-toggle .explink {
    font-size: 1.1em;
    font-weight: 500;
}
.experiments .accordion-toggle .badgelist {
    margin-bottom: 4px;
}
.experiments .card-header {
    border: none;
    background-color: #F5FBFF;
}
.experiments .nav .nav-header {
    font-size: 11px;
    font-weight: normal;
    color: #000000;
    text-shadow: none;
    font-style: italic;
    text-transform: none;
}
/* end index page */

/* dataset tiles */
.ds-thumb canvas {
    width: 100px;
}
/* end dataset tiles */

/* mydata page */
.mydata .accordion-toggle .explink {
    font-size: 1.1em;
    font-weight: 500;
    max-width: 67%;
    cursor: pointer;
}
.mydata .accordion-toggle .badgelist {
    margin-bottom: 4px;
}
.mydata .card-body p {
    width: 75%;
}
.mydata .card-body .thumbnails li {
    margin-bottom: 5px;
    margin-top: 2px;
}
.mydata .nav .nav-header {
    font-size: 11px;
    font-weight: normal;
    color: #000000;
    text-shadow: none;
    font-style: italic;
    text-transform: none;
}

.borderless .list-group-item {
    border: none;
    background-color: transparent !important;
}

/* end index page */
/* autocomplete defaults */
.ui-autocomplete {
    z-index: 1234 !important;
}
/* end autocomplete defaults */
/* begin search */
.search_result {
    margin-top: 0.3em;
}
.search_result div {
    margin-left: 1em;
}
.search_result div div {
    margin-left: 1em;
}
.tab-pane {
    padding: 10px;
}

.nav-tabs {
    margin-bottom: 0;
}

/* end search */

.page-header {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-bottom: 9px;
    border-bottom: 1px solid #eeeeee
}

.datasets.thumbnails {
    list-style-type: none;
    padding-inline-start: 0;
}
.list-inline-item {
    margin-left: 10px;
    margin-top: 5px;
}
.list-inline-item>a {
    margin-bottom: 0;
}

/* Ensure modals have vertical scrollbars if necessary */
.modal-dialog {
    overflow-y: initial !important
}
.modal-body {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
}
.w-66 {
    width: 66%;
}

/* Make headings smaller than the Bootstrap default */
h1 {
    font-size: 2rem
}
h2 {
    font-size: 1.75rem
}
h3 {
    font-size: 1.5rem
}
h4 {
    font-size: 1.25rem
}
h5 {
    font-size: 1.125rem
}

form p.help-block {
    margin-left: 15px;
    font-size: .87rem;
    color: #888
}

footer {
    border-top: 1px solid #ced4da
}